
import VehiclesList from '@/components/VehiclesList.vue'
import { Component, Vue } from 'vue-property-decorator'
import Main from '@/layouts/Main.vue'

@Component({
  components: {
    VehiclesList,
    Main,
  },
  metaInfo: {
    title: 'Vehicles',
  },
})
export default class Vehicles extends Vue {}
