var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"fill-height align-start background-gray-bg main",class:{
    'max-w-80-percent': _vm.$vuetify.breakpoint.xlOnly && !_vm.unsetXlCrop,
    'padding-l-8': !_vm.paddingX,
    'padding-r-5': _vm.scrollSupported && !_vm.paddingX,
    'padding-r-8': !_vm.scrollSupported && !_vm.paddingX,
    'padding-y-8': !_vm.paddingY,
  },style:(_vm.style),attrs:{"id":"main-layout-container","fluid":""}},[_c('div',{staticClass:"w-full"},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }