
import { Component, Vue } from 'vue-property-decorator'
import CUCollectionTable from '@/components/CUCollectionTable.vue'
import { TableAction } from '@/models/TableAction'
import VehicleSidebar from '@/components/VehicleSidebar.vue'
import { DataTableColumn } from '@/models/DataTableColumn'
import { EventBus } from '@/utils/eventBus'
import sidebar from '@/store/modules/sidebar'
import client from '@/services/vehicle'
import { VehicleDetailEntity } from '@/models/dto'
import HoldUpModal from './HoldUpModal.vue'

@Component({
  components: {
    VehicleSidebar,
    CUCollectionTable,
    HoldUpModal,
  },
})
export default class VehiclesList extends Vue {
  tableView = client.tableView

  isDeleteVehicleModalOpen = false
  selectedVehicleRows: VehicleDetailEntity[]

  refresh(): void {
    sidebar.pop()
    EventBus.$emit('refresh-tableview')
  }

  error(e: any): void {
    // TODO: Provide user feedback (e.g. toaster) once designs are finalized.
  }

  // Add Vehicle //
  handleAddVehicle(): void {
    sidebar.push({
      component: VehicleSidebar,
      width: 672,
      props: {
        activeVehicleDetailId: null,
      },
      title: 'Add Vehicle',
      persist: true,
    })
  }

  handleDeleteVehicle(selectedVehicleRows: VehicleDetailEntity[]): void {
    this.isDeleteVehicleModalOpen = true
    this.selectedVehicleRows = selectedVehicleRows
  }

  deleteVehicle(): void {
    if (this.selectedVehicleRows.length) {
      client.delete(this.selectedVehicleRows[0].vehicleId).then(() => {
        this.isDeleteVehicleModalOpen = false
        this.selectedVehicleRows = []
        this.refresh()
      })
    }
  }

  // Table //
  count = 0
  search = ''
  dropdown = ['Placeholder_01', 'Placeholder_02']

  columns: DataTableColumn[] = [
    {
      _t_id: 'd7e40f4f-fbe7-4379-87b6-b51448b09a58',
      text: 'Name',
      value: 'vehicleName',
      filterable: true,
      sortable: true,
      filterBySearch: true,
      filterProp: 'vehicleName',
      filterType: 'contains',
      sortProp: 'vehicleName',
      type: 'link',
      linkHandler: (row: VehicleDetailEntity): void => {
        sidebar.popAllAndPush({
          component: VehicleSidebar,
          width: 672,
          props: {
            activeVehicleDetailId: row.vehicleId,
            isModeEdit: true,
            title: row.vehicleName,
          },
          persist: true,
        })
      },
      width: 160,
    },
    {
      _t_id: '076b810f-cfc0-47ac-8e15-00d57aed9fc0',
      text: 'Type',
      value: 'vehicleTypeName',
      filterProp: 'vehicleTypeName',
      filterType: 'contains',
      sortProp: 'vehicleTypeName',
      width: 120,
    },
    {
      _t_id: '3a60418a-359b-411f-bbe9-511171f5df0a',
      text: 'Capacity',
      value: 'passengerCapacity',
      filterProp: 'passengerCapacity',
      filterType: 'contains',
      sortProp: 'passengerCapacity',
      width: 120,
    },
    {
      _t_id: '934e8d2c-cb43-4f8c-8306-ef7a7c798831',
      text: 'Make',
      value: 'vehicleMake',
      filterProp: 'vehicleMake',
      filterType: 'contains',
      sortProp: 'vehicleMake',
      width: 160,
    },
    {
      _t_id: '17572609-9e12-4209-b889-fe35f2cd5cca',
      text: 'Model',
      value: 'vehicleModel',
      filterProp: 'vehicleModel',
      filterType: 'contains',
      sortProp: 'vehicleModel',
      width: 160,
    },
    {
      _t_id: 'bff8b2e4-e4f4-4d26-8a25-316eb48d3ed1',
      text: 'Year',
      value: 'vehicleYear',
      filterProp: 'vehicleYear',
      filterType: 'contains',
      sortProp: 'vehicleYear',
      width: 110,
    },
    {
      _t_id: '9d1a666e-88cc-40ac-942d-32cfd507eca1',
      text: 'Garage',
      value: 'garageName',
      filterProp: 'garageName',
      filterType: 'contains',
      sortProp: 'garageName',
      width: 160,
    },
    {
      _t_id: '9d1a666e-88cc-40ac-942d-32cfd507eca2',
      text: 'Included',
      value: 'dummy',
      sortProp: 'dummy',
      computedText: (row): string => (row.dummy ? 'Not Included' : 'Included'),
      width: 160,
    },
  ]

  mounted(): void {
    EventBus.$on('vehicles:refresh', this.refresh)
    EventBus.$on('vehicles:error', this.error)
    EventBus.$on('vehicles:delete', this.handleDeleteVehicle)
  }

  beforeDestroy(): void {
    EventBus.$off('vehicles:refresh', this.refresh)
    EventBus.$off('vehicles:error', this.error)
    EventBus.$off('vehicles:delete', this.handleDeleteVehicle)
  }
}
