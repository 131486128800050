
import { Vue, Component, Prop } from 'vue-property-decorator'
import { numberToPixels } from '@/utils/style'
@Component
export default class Main extends Vue {
  @Prop({ default: undefined, type: [Number, String] }) readonly paddingY!:
    | number
    | string
  @Prop({ default: undefined, type: [Number, String] }) readonly paddingX!:
    | number
    | string
  @Prop({ type: Boolean }) readonly unsetXlCrop!: boolean

  scrollSupported = navigator.userAgent.indexOf('AppleWebKit') !== -1

  get style(): Record<string, string> {
    const style: Record<string, string> = {}

    if (this.paddingY) {
      style.paddingTop = numberToPixels(this.paddingY)
      style.paddingBottom = numberToPixels(this.paddingY)
    }

    if (this.paddingX) {
      style.paddingLeft = numberToPixels(this.paddingX)
      style.paddingRight = numberToPixels(this.paddingX)
    }

    return style
  }
}
